
import {
  _360ProvidersLinks,
  _360ProvidersParams,
  _360ProvidersSuffixes,
  _360Providers,
} from "@evercam/shared/constants/360Providers"
import SnapshotEditor from "@/components/SnapshotEditor"
import { MESSAGES_TYPES } from "@evercam/360/components/constants"
import RecordingsDialog from "@/components/3dAppsDialogs/RecordingsDialog"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  InfoPage,
} from "@evercam/shared/types"

export default {
  name: "Viewer360",
  meta: {
    pageId: AnalyticsEventPageId.ThreeSixty,
  },
  components: { RecordingsDialog, FrameViewer, SnapshotEditor },
  middleware({ redirect, from, $permissions }) {
    const accountStore = useAccountStore()
    const projectStore = useProjectStore()
    if (!accountStore.token) {
      accountStore.redirectUrl = from.fullPath

      return redirect("/v2/users/signin")
    }
    if (
      !$permissions.project.has._360View() ||
      !$permissions.user.can.access._360()
    ) {
      redirect(`${projectStore.projectRoute}/info/${InfoPage.ThreeSixtyView}`)
    }
  },
  data: () => ({
    recordingsCameraExid: undefined,
    project360: null,
    providerLinks: _360ProvidersLinks,
    providerParams: _360ProvidersParams,
    providersSuffixes: _360ProvidersSuffixes,
    isEditingImage: false,
    currentSnapshot: null,
    initialQueryParams: {},
    currentQueryParams: {},
  }),
  head() {
    return {
      title: `${
        this.projectStore.selectedProjectName || "Evercam"
      } | 360° View`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    firstCamera() {
      return this.projectStore.selectedProjectCameras[0] || {}
    },
    params() {
      var extraParams =
        this.project360.integrationType === _360Providers.Evercam
          ? `&unit=${this.accountStore.unitSystem}&` +
            new URLSearchParams(this.initialQueryParams)
          : ""

      return `${this.providerParams[this.project360.integrationType]}${
        this.project360.providerID
      }${extraParams}`
    },
  },
  watch: {
    "$route.query.viewer"(_, old) {
      if (!old) {
        return
      }
      this.isEditingImage = false
    },
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.initialQueryParams = { ...this.$route.query, user: "user" }
    await this.get360Data()
    this.saveAnalyticsEvent("open_360_app", "dashboard")
  },
  methods: {
    async get360Data() {
      let response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/360/${this.projectStore.selectedProjectExid}.json`
      )
      this.project360 = await response.json()
      if (!this.project360.providerPrefix) this.project360.providerPrefix = ""

      if (!this.project360.providerSuffix) {
        this.project360.providerSuffix =
          this.providersSuffixes[this.project360.integrationType]
      }
    },
    async onMessageReceived(data) {
      switch (data?.type) {
        case MESSAGES_TYPES.liveView:
          this.openRecordings(data.cam)
          break
        case MESSAGES_TYPES.editSnapshot:
          if (!this.isEditingImage) {
            this.openImageEditor(data?.url)
          }
          break
        case MESSAGES_TYPES.analyticsEvent:
          this.saveAnalyticsEvent(data?.action)
          break
        case MESSAGES_TYPES.history:
          this.pushQueryParams(data?.history)
          break
        case MESSAGES_TYPES.shareLink:
          this.shareLink()
          break
        default:
          break
      }
    },
    openRecordings(cameraExid) {
      this.recordingsCameraExid = null
      this.$setTimeout(() => {
        this.recordingsCameraExid = cameraExid
      })
    },
    openImageEditor(urlData) {
      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
        camera: this.firstCamera,
      }
      this.isEditingImage = true
    },
    saveAnalyticsEvent(action, context) {
      context = context ?? "360"
      this.$analytics.saveEvent(`${action}_${context}`)
    },
    pushQueryParams(parameters) {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        ...parameters,
      }
      this.$router.push({
        path: this.$route.path,
        query: this.currentQueryParams,
      })
    },
    shareLink() {
      const link = window.location.href
      navigator.clipboard.writeText(link)
      this.$notifications.success("Link copied to clipboard.")
    },
  },
}
