
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import RecordingsDateTimePicker from "@/components/recordings/RecordingsDateTimePicker"
import PlayersActionButtons from "@/components/recordings/player/PlayersActionButtons"
import CameraStatusSnackbar from "@/components/CameraStatusSnackbar"
import SnapshotEditor from "@/components/SnapshotEditor"
import { useSnapshotStore } from "@/stores/snapshots"
import { RecordingsTabs, useRecordingsStore } from "@/stores/recordings"
import { useLayoutStore } from "@/stores/layout"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

export default {
  components: {
    RightSidebarContent,
    EvercamPlayer,
    RecordingsDateTimePicker,
    EvercamLoadingAnimation,
    PlayersActionButtons,
    SnapshotEditor,
    CameraStatusSnackbar,
  },
  props: {
    cameraExid: {
      type: String,
      default: undefined,
    },
    itwinButtonEnabled: {
      type: Boolean,
      debugger: false,
    },
    origin: {
      type: String,
      default: "snapshot",
    },
  },
  data: () => ({
    isLive: false,
    isLoadingRecordings: false,
    showRecordingsDialog: false,
    playerDimensions: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    editorTranslation: 0,
  }),
  computed: {
    ...mapStores(
      useSnapshotStore,
      useRecordingsStore,
      useCameraStore,
      useLayoutStore,
      useAccountStore
    ),
    recordingsDialogClass() {
      if (this.isLoadingRecordings || !this.showRecordingsDialog) {
        return
      }

      if (this.layoutStore.isRightSidebarVisible) {
        return "recordings-dialog--sidebar-opened"
      }

      return "recordings-dialog--sidebar-closed"
    },
    canShowRightSidebar() {
      return this.showRecordingsDialog && !this.isLoadingRecordings
    },
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    snapshotsInterval() {
      return {
        from: this.$moment
          .tz(this.recordingsStore.selectedTimestamp, this.timezone)
          .startOf("hour")
          .utc()
          .format(),
        to: this.$moment
          .tz(this.recordingsStore.selectedTimestamp, this.timezone)
          .endOf("hour")
          .utc()
          .format(),
      }
    },
    isBrainToolActive(): boolean {
      return this.recordingsStore.selectedTab === RecordingsTabs.BrainTool
    },
    editorTranslationStyles() {
      return {
        transform: `translate(0, ${this.editorTranslation}px)`,
      }
    },
  },
  watch: {
    canShowRightSidebar(value) {
      this.layoutStore.isRightSidebarDrawerButtonVisible = value
    },
    showRecordingsDialog(value) {
      if (value) {
        this.isLive = false
      }
    },
    cameraExid(value) {
      if (!value) {
        this.closeLiveViewDialog()
      } else {
        this.onCameraSelected(value)
      }
    },
    "recordingsStore.isEditing"(v) {
      const translationStart = v ? -this.layoutStore.footerRect.height : 0
      const translationEnd = v ? 0 : -this.layoutStore.footerRect.height

      this.editorTranslation = translationStart
      this.$nextTick(() => {
        this.$setTimeout(() => {
          this.editorTranslation = translationEnd
        })
      })
    },
  },
  mounted() {
    this.updatePlayerDimensions()
  },
  beforeDestroy() {
    this.recordingsStore.reset()
  },
  methods: {
    async onCameraSelected(cameraExid) {
      this.isLoadingRecordings = true
      this.showRecordingsDialog = true
      await this.cameraStore.selectCamera(cameraExid)
      await this.snapshotStore.updateLatestAndOldestSnapshots(cameraExid)
      let timestamp = this.snapshotStore.latestSnapshotTimestamp
      if (
        !this.snapshotStore.cameraExid ||
        this.snapshotStore.cameraExid !== cameraExid ||
        !this.snapshotStore.latestSnapshotTimestamp
      ) {
        this.snapshotStore.updateLatestAndOldestSnapshots(cameraExid)
      }
      this.recordingsStore.selectedTimestamp = timestamp
      this.openLiveViewDialog()
    },
    openLiveViewDialog() {
      this.$setTimeout(() => {
        this.isLoadingRecordings = false
      }, 500)
    },
    updatePlayerDimensions() {
      const rightSidebarWidth =
        this.$vuetify.breakpoint.mdAndUp &&
        this.layoutStore.isRightSidebarVisible
          ? 270
          : 0
      this.playerDimensions = {
        width: window.innerWidth - rightSidebarWidth,
        height: window.innerHeight,
      }
    },
    closeLiveViewDialog() {
      this.recordingsStore.isItwinActive = false
      this.showRecordingsDialog = false
      this.recordingsStore.changeXrayVisibility(false)
    },
  },
}
